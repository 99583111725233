import axios from "axios";
export const selectLiveListisReferral = (params) =>
  axios.get(
    `/web/live/selectLiveList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&isReferral=${params.isReferral}`
  ); // 获取直播列表

export const selectLiveList = (params) =>
  axios.get(
    `/web/live/selectLiveList?pageNum=${params.pageNum}&pageSize=${params.pageSize}${params.classId? '&classId='+params.classId:''}`
  ); // 获取直播列表

export const selectLiveTypeList = () =>
  axios.get(`/biz/liveClass/list?pageNum=${0}&pageSize=${1000}`); // 获取直播列表
