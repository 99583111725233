<template>
  <div class="md-pages-home">
    <div class="md-swiper-con">
      <div
        class="opacity-bg"
        :style="{ 'background-image': `url(${oBg})` }"
      ></div>

      <div class="md-swiper-container">
        <div class="container-main">
          <div class="md-category-box">
            <div class="md-category-box-title">全部课程分类</div>
            <ul class="menu-list">
              <li
                class="menu-item"
                v-for="(item, index) in drawList"
                :key="index"
                @mouseenter="drawShow(index)"
                @mouseleave="drawHide"
              >
                <span class="menu-item-name">{{ item.name }}</span>
                <p class="menu-item-child">
                  <span v-for="(list, i) in item.categoryList" :key="i">
                    <span
                      class="menu-item-child-name"
                      @click="handleItemClick(item.rid, list.rid)"
                    >
                      {{ list.name }}
                    </span>
                    <span v-if="i !== item.categoryList.length - 1">
                      /</span
                    ></span
                  >
                </p>
                <i class="el-icon-caret-right a-r"></i>
              </li>
            </ul>
            <div
              class="draw-box"
              v-show="drawFlag"
              @mouseenter="drawFlag = true"
              @mouseleave="drawFlag = false"
            >
              <p class="box-title">{{ drawInfo.name }}</p>
              <p>
                <span
                  v-for="(c, i) in drawInfo.categoryList"
                  :key="i"
                  @click="handleItemClick(drawInfo.rid, c.rid)"
                  >{{ c.name }}</span
                >
              </p>
              <div class="box-remen" v-if="drawInfo.popularList">
                <p class="box-title" v-if="drawInfo.popularList.length > 0">
                  热门课程
                </p>
                <div class="footer-item">
                  <div
                    :class="['item', +i === 1 ? 'item-active' : '']"
                    v-for="(c, i) in drawInfo.popularList.slice(0, 2)"
                    :key="i"
                    @click="turnVideo(c)"
                  >
                    <img :src="`/video-dev${c.cover}`" alt="" />
                    <div class="item-p">{{ c.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="md-swiper-box">
            <el-carousel :interval="4000" @change="handleCarousel">
              <el-carousel-item
                v-for="(item, index) in swiperList"
                :key="index"
              >
                <img
                  class="swiper-img"
                  :src="`/video-dev${item.image}`"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="md-class-con" v-for="(item, index) in tuijianList" :key="index">
      <div class="md-class-container">
        <div class="information-title">
          <div></div>
          <div>最近上线</div>
          <div class="title-right" @click="getmore('/category')">
            查看更多
            <img src="@/assets/img/new-img/arr-left.png" alt="" />
          </div>
        </div>
        <ul class="md-class-list" v-if="item.vhomeRecommendedDetails">
          <li v-for="(v, i) in item.vhomeRecommendedDetails" :key="i">
            <MdItemClassComponent :classInfo="v" />
          </li>
        </ul>
      </div>
    </div>
    <!-- 渲染最近上线和直播 -->
    <div class="md-class-con">
      <div class="md-class-container">
        <div class="information-title">
          <div></div>
          <div>直播</div>
          <div class="title-right" @click="getmore('/live')">
            查看更多
            <img src="@/assets/img/new-img/arr-left.png" alt="" />
          </div>
        </div>
        <ul class="md-class-list">
          <li v-for="(v, i) in liveList.slice(0, 4)" :key="i"  style="margin-top: 10px; margin-right: 10px">
            <LiveItemClassComponent :classInfo="v" />
          </li>
        </ul>
      </div>
    </div>

    <!-- 酷学资讯 -->
    <div
      class="md-class-con"
      style="background-color: #fcffff; padding: 60px 0"
    >
      <div class="md-information">
        <div class="information-title">
          <div></div>
          <div>资讯</div>
          <div class="title-right" @click="getmore('/news')">
            查看更多
            <img src="@/assets/img/new-img/arr-left.png" alt="" />
          </div>
        </div>
        <div class="news-content" v-if="schools.length > 0">
          <img
            :src="`/video-dev${schools[0].cover}`"
            alt=""
            style="width: 560px; height: 315px; border-radius: 8px"
          />
          <ul
            style="
              box-shadow: 0px 4px 20px 0px rgba(210, 198, 180, 0.4);
              padding: 20px;
            "
          >
            <li
              v-for="(v, i) in schools"
              :key="i"
              @click="handleTurnDetail(v)"
              style="display: flex"
            >
              <span class="red-circle"></span>
              <div class="md-information-news">
                <div class="news-right">
                  <p>{{ v.title }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="con" id="con">
      <!-- //推荐课程 -->
      <div
        class="md-class-con"
        style="
          padding: 60px 0;
          background: linear-gradient(360deg, #f4f5f9 0%, #ffe8d6 100%);
        "
        v-if="videoLists.length > 0"
      >
        <div :class="['md-category']">
          <div class="information-title">
            <div></div>
            <div>推荐课程</div>
            <div
              class="title-right"
              @click="getmore('/category')"
              style="color: #ff882e"
            >
              查看更多
              <img src="@/assets/img/arr-right-orange.png" alt="" />
            </div>
          </div>
          <div class="type-list">
            <span
              :class="[
                'type-item',
                +pIndex === index ? 'type-item-active' : '',
              ]"
              v-for="(item, index) in videoLists"
              :key="index"
              @click="handleCategory(index)"
              >{{ item.name }}</span
            >
          </div>

          <ul class="md-class-list">
            <li
              v-for="(v, i) in videoLists[pIndex].vhomeRecommendedDetails"
              :key="i"
            >
              <MdItemClassComponent :classInfo="v" />
            </li>
          </ul>
        </div>
      </div>

      <!-- 酷学名师 -->
      <div class="md-class-con" style="padding: 60px 0; background-color: #fff">
        <div class="md-teacher" v-if="teacherList.length > 0">
          <div class="information-title">
            <div></div>
            <div>名师</div>
          </div>
          <p>好老师，严格优选上百位优秀教师，打造优质主讲团队</p>
          <div style="width: 100%; height: 490px">
            <el-carousel indicator-position="outside" height="490px">
              <el-carousel-item
                v-for="(item, index) in teacherList"
                :key="index"
              >
                <div class="teacher-box">
                  <div class="teacher-info">
                    <p>{{ item.name }}</p>
                    <ul>
                      <li
                        class="desc"
                        v-for="(list, index) in item.description
                          .split('\n')
                          .slice(0, 5)"
                        :key="index"
                      >
                        {{ list }}
                      </li>
                    </ul>
                    <div class="btn-teacher" @click="goteacher">
                      查看更多名师
                    </div>
                  </div>
                  <img
                    class="teacher-photo"
                    :src="`/video-dev${item.photo}`"
                    alt=""
                  />
                  <div class="key-index">
                    <span style="font-size: 26px; color: #ff882e">{{
                      "0" + (index + 1)
                    }}</span>
                    <span>/{{ "0" + teacherList.length }}</span>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作院校 -->
    <!-- <div
      class="cooperation-school"
      style="background: #f2f5fa; padding: 60px 0 70px 0"
      v-if="schoolData.length > 0"
    >
      <div class="information-title">
        <div></div>
        <div>合作院校</div>
      </div>
      <MdSchoolClassComponent :schools="schoolData" />
    </div> -->
    <div class="home-bgi"></div>
  </div>
</template>

<script>
// import { mdApiBase } from '@/ddd/config.service.js'

import { getIPLogin } from "@/axios/user";
import { getTeacher } from "@/axios/teachers";
import { selectArticleList } from "@/axios/news";
import { getCategoryList } from "@/axios/course";
import { selectLiveListisReferral } from "@/axios/live";
import { getBannerList, getAuthorization, getSChoolList } from "@/axios/home";
import MdItemClassComponent from "../components/class/mdItem/index.vue";
import LiveItemClassComponent from "../components/class/liveItem/live.vue";
// import MdSchoolClassComponent from "../components/class/mdSchool/index.vue";

export default {
  name: "MdPagesHomeComponent",
  components: {
    MdItemClassComponent,
    // MdSchoolClassComponent,
    LiveItemClassComponent,
  },
  data() {
    return {
      drawFlag: false,
      drawList: [],
      drawInfo: {},
      swiperList: [],
      videoLists: [],
      schools: [], //学校图片列表
      oBg: "",
      params: {
        page: 1,
      },
      total: 20,
      tuijianList: [],
      liveparams: {
        pageNum: 1,
        pageSize: 10,
        isReferral: 1,
      },
      liveList: [],
      teacherList: [],
      pIndex: null,
      teacherIndex: 0,
      schoolData: [],
    };
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem("isLogin");
    },
    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
    deptNameinfo() {
      return window.localStorage.getItem("deptName");
    },
  },
  created () {
    let ticket = this.$route.query.ticket || null;
    let ticketToken = this.$route.query.token || null;
    if (ticket) {
      this.Authorization(ticket);
    }
    if (ticketToken) {
       this.getIPLogin(ticketToken);
    }
    if (!localStorage.getItem("isLogin")) {
      // 用户没登录查询是否在规定ip段内
      this.getIPLogin("12");
    } else {
      this.init();
    }
    this.getTuiJian();
  },
  methods: {
    getmore(paths) {
      console.log(paths, "path");
      this.$router.push({
        path: paths,
      });
    },
    changTeacher(index) {
      this.teacherIndex = index;
    },
    async getSChoolList() {
      const res = await getSChoolList({
        pageNum: 1,
        pageSize: 10,
      });
      if (res.code == 200) {
        console.log(res.rows, "rows");
        this.schoolData = res.rows;
      }
    },
    //验证ticket
    async Authorization(ticket) {
      const res = await getAuthorization(ticket);
      if (res.code == 200) {
        localStorage.setItem("Authorization", res.token); // 存储登录token
        localStorage.setItem("isLogin", true);
        this.$store.state.isLogin = true;
        localStorage.setItem("logintype", 3);
        localStorage.setItem("userInfo", JSON.stringify({ dept: res.dept }));
        this.$router.push({
          path: "/home",
        });
      }
    },
    handleTurnDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          rid: item.rid,
          item: JSON.stringify(item),
        },
      });
    },
    // 轮播图切换
    carouselChange(n) {
      this.teacherIndex = n;
    },
    // 判断当前ip是否在规定ip范围内
    async getIPLogin(token) {
      const res = await getIPLogin(token);
      if (res.code === 200) {
        if (res.token) {
          localStorage.setItem("Authorization", res.token); // 存储登录token
          localStorage.setItem("isLogin", true);
          this.$store.state.isLogin = true;
          localStorage.setItem("isipLogin", true);
          console.log(this.$store.state.isLogin, "this.$store.state.isLogin");
          localStorage.setItem("deptName", res.dept.deptName);
          localStorage.setItem("userInfo", JSON.stringify({ dept: res.dept }));
          this.$store.state.user.dept = res.dept;
          sessionStorage.setItem("deptId", res.dept.deptId);
          sessionStorage.setItem("deptName", res.dept.deptName);
        }
      }
      this.init();
    },
    //获取教师信息
    async getTeacher() {
      const res = await getTeacher({
        pageNum: 1,
        pageSize: 6,
      });
      if (res.code === 200) {
        this.teacherList = res.rows;
      }
    },
    turnVideo(classInfo) {
      if (this.isLogin) {
        if (classInfo.videoId) {
          this.$router.push({
            path: "/courseDetail",
            query: {
              rid: classInfo.videoId,
            },
          });
        } else {
          this.$router.push({
            path: "/courseDetail",
            query: {
              rid: classInfo.rid,
            },
          });
        }
      } else {
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
    handleCategory(index) {
      this.pIndex = index;
    },
    async selectLiveList() {
      const res = await selectLiveListisReferral(this.liveparams);
      if (res.code === 200) {
        this.liveList = res.rows;
      }
    },
    toPage(path) {
      this.$router.push(path);
    },
    init() {
      this.getCategoryList();
      this.getBannerList();
      this.getSChoolList();
      this.getTeacher();
      this.selectArticleList();
      this.selectLiveList();
    },
    async getBannerList() {
      // 获取banner
      const res = await getBannerList({
        type: 1,
      });
      if (res.code === 200) {
        this.swiperList = res.data;
        this.oBg = this.swiperList[0].img;
      }
    },
    async getCategoryList() {
      const res = await getCategoryList(1);
      if (res.code === 200) {
        this.drawList = res.data;
      }
    },
    // 获取资讯列表
    async selectArticleList() {
      const params = {
        type1: 1,
        pageNum: 1,
        pageSize: 9,
      };
      const res = await selectArticleList(params);
      if (res.code === 200) {
        this.schools = res.rows;
      }
    },
    handleItemClick(pId, cId) {
      this.$router.push({
        path: "/category",
        query: {
          pId,
          cId,
        },
      });
    },
    goteacher() {
      this.$router.push({
        path: "/teachers",
      });
    },
    handleCarousel(e) {
      this.oBg = this.swiperList[e].img;
    },
    drawShow(index) {
      this.drawFlag = true;
      this.drawInfo = this.drawList[index];
    },
    drawHide() {
      this.drawFlag = false;
    },
    handleCurrentChange(val) {
      this.params.page = val;
    },
    async getTuiJian() {
      try {
        const res = await this.$http.get("/web/nominate/getNominateList");
        console.log(res);
        this.tuijianList = res.data.filter((item) => item.name == "最近上线");
        this.videoLists = res.data.filter((item) => item.name !== "最近上线");
        this.pIndex = 0;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.md-pages-home {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #f3f5f5;

  .home-bgi {
    width: 100%;
    max-width: 1920px;
    background-image: url("../assets/img/home-bgis.png");
    background-size: 100% 100%;
    height: 259px;
    background-repeat: no-repeat;
  }
  h3 {
    padding-left: 5px;
  }

  .md-swiper-con {
    max-width: 1920px;
    min-width: 1200px;

    width: 100%;
    background-color: #f3f5f5;
    position: relative;
    margin: 0 auto;

    .opacity-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 180px;
      width: 100%;
      opacity: 0.3;
      filter: blur(100px);
    }
  }

  .md-swiper-container {
    width: 100%;
    background-color: #f3f5f5;
    margin: 20px auto;
    display: flex;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 500px;

    .container-main {
      width: 1200px;
      height: 410px;
      margin: 20px auto;
      display: flex;
      padding: 20px 0px;
      align-items: center;
      justify-content: center;
    }

    .md-user-box {
      width: 220px;
      height: 336px;
      background: #fff;
      flex-shrink: 0;
      border-radius: 0 10px 10px 0;
      margin-left: 12px;
      background-image: url("../assets/img/bgc.png");
      border-radius: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .user-title {
        width: 112px;
        // height: 22px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        text-align: center;
      }

      p {
        width: 72px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #808080;
        line-height: 17px;
      }

      .school {
        display: flex;
        display: flex;
        align-items: center;

        > :nth-child(2) {
          margin-left: 5px;
        }
      }

      > :nth-child(1) {
        width: 66px;
        height: 66px;
        border-radius: 50%;
      }

      > :nth-child(2) {
        margin-top: 12px;
      }

      .box-item {
        width: 140px;
        line-height: 36px;
        font-size: 14px;
        background: #e2f5f3;
        border-radius: 20px;
        color: @md-primary-color;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
      }

      .box-items {
        width: 140px;
        line-height: 36px;
        font-size: 14px;
        background: #e2f5f3;
        border-radius: 20px;
        color: @md-primary-color;
        text-align: center;
        cursor: pointer;
        margin-top: 39px;
      }
    }

    .md-category-box {
      width: 269px;
      height: 410px;
      background: #fff;
      z-index: 99;
      flex-shrink: 0;
      border-radius: 12px;
      position: relative;
      .md-category-box-title {
        padding: 12px 0px;
        margin: 0 10px;
        border-bottom: 1px solid #e6e6e6;

        text-align: center;
        color: @md-primary-color;
      }

      .menu-list {
        height: calc(413px - 46px);
        overflow-y: scroll;
        // padding: 15px;

        &::-webkit-scrollbar {
          display: none;
        }

        .menu-item {
          cursor: pointer;
          // height: 20px;
          padding: 0 15px;
          color: #333;
          font-size: 14px;
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 15px;
          .menu-item-name {
            display: inline-block;
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: bold;
            color: #333333;

            // &:hover {
            //   color: @md-primary-color;
            //   background: rgba(255, 255, 255, 0.1);
            // }
          }

          .menu-item-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 400px;
            font-size: 12px;
            text-align: left;
            margin-left: 10px;

            .menu-item-child-name {
              color: #666666;
              margin-left: 8px;

              &:hover {
                color: @md-primary-color;
                background: rgba(255, 255, 255, 0.1);
              }
            }
          }

          .space {
            margin: 0 3px;
          }

          .a-r {
            // position: absolute;
            // right: 10px;
            // top: 15px;
            font-size: 10px;
            color: #ccc;
          }
        }
      }

      .draw-box {
        position: absolute;
        bottom: 0;
        left: 268px;
        width: 258px;
        z-index: 99;
        height: 250px;
        background: #fff;
        border-radius: 0px;
        border-left: 1px solid #f5f7fa;
        padding: 16px;
        box-sizing: border-box;

        .box-remen {
          position: absolute;
          bottom: 14px;
        }

        .box-title {
          margin-bottom: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          font-weight: 700;
        }

        p {
          display: flex;
          flex-wrap: wrap;

          b {
            color: #1c1f21;
          }

          span {
            color: #6d7278;
            margin-right: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            display: inline-block;
            font-size: 12px;

            &:hover {
              color: @md-primary-color;
            }
          }
        }
      }
    }

    .md-swiper-box {
      width: 911px;
      max-width: 1920px;
      height: 410px;
      margin-left: 20px;
      border-radius: 12px;
    }
  }

  .md-class-con {
    width: 100%;
    color: #f5f7fa;
    max-width: 1920px;
    margin: 0 auto;
    background-color: #f3f5f5;
    padding: 42px 0 60px 0;

    .md-class-container {
      width: 1200px;
      margin: 0 auto;

      // padding: 40px 0;
      h3 {
        color: #000000;
        margin-bottom: 20px;
        font-size: 29px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
      }
      .md-class-list {
        display: flex;
        flex-wrap: wrap;
        // margin-top: 28px;

        li {
          &:nth-child(4n) {
            .md-item-class-component {
              margin-right: 0;
            }
          }
        }
      }

      .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .cooperation-school {
    width: 100%;
    overflow: hidden;

    h3 {
      width: 1152px;
      margin: 10px auto 20px;
      color: @md-primary-color;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 29px;
    }
  }
}

.md-teacher {
  color: #fff;
  margin: 0 auto;
  width: 1200px;

  h3 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 29px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
  }
  ::v-deep {
    .el-carousel__item {
      display: flex;
      align-items: end;
    }
  }
}

.md-information-news {
  display: flex;
  align-items: center;
  cursor: pointer;

  .news-right {
    margin-left: 12px;

    > :nth-child(1) {
      font-size: 16px;
      font-family: PingFang-SC, PingFang-SC;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
    }

    > :nth-child(2) {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      margin-top: 8px;
    }

    > :nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top: 8px;
    }
  }
}

.md-class-list {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 28px;

  li {
    &:nth-child(4n) {
      .md-item-class-component {
        margin-right: 0;
      }
    }
  }
}
.md-information {
  margin: 0 auto;
  width: 1200px;
  color: #000000;

  h3 {
    color: #000000;
    margin-bottom: 20px;
    font-size: 29px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    width: 560px;
    height: 315px;
    margin-left: 20px;
    border-radius: 12px;
    li {
      width: 570px;
      align-items: center;
    }
  }
}

.md-category {
  width: 1200px;
  margin: 0 auto;

  > :nth-child(1) {
    display: flex;
    align-items: center;
  }

  h3 {
    color: #000000;
    margin-bottom: 20px;
    font-size: 29px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
  }

  .type-list {
    margin-bottom: 20px;
    margin-left: 30px;
    text-align: center;

    b {
      font-size: 14px;
    }

    .type-item {
      color: #1c1f21;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 12px;
      border-radius: 6px;
      margin: 0 12px 12px 0;
      cursor: pointer;
      width: 80px;
    }

    .type-item-active {
      position: relative;
      color: @md-primary-color;

      &::after {
        //主要是这个
        content: "";
        width: 20px;
        height: 5px;
        display: block;
        margin: 0 auto;
        margin-top: 5px;
        background-color: @md-primary-color;
        border-radius: 30px;
        position: absolute;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }
  }

  .md-class-list {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 28px;

    li {
      &:nth-child(4n) {
        .md-item-class-component {
          margin-right: 0;
        }
      }
    }
  }
}

.footer-item {
  display: flex;
  align-items: center;

  .item-active {
    margin-left: 12px;
  }

  .item {
    img {
      width: 108px;
      height: 72px;
    }

    .item-p {
      width: 108px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.btn-teacher {
  width: 144px;
  height: 40px;
  background: #ff882e;
  border-radius: 21px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-top: 93px;
  border: 1px solid @md-primary-color;
  cursor: pointer;
}

.information-title {
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  > :nth-child(1) {
    position: absolute;
    width: 100%;
    top: 0;

    font-weight: 900;
    font-size: 28px;
    color: #e1e6f0;
    line-height: 40px;
  }
  > :nth-child(2) {
    z-index: 9;
    width: 100%;
    font-size: 36px;
    font-weight: bold;
    color: #57452e;
  }
  > :nth-child(3) {
    position: absolute;
    font-size: 14px;
    color: #808080;
    right: 0;
    cursor: pointer;
    z-index: 99;
  }
}

.red-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ff671e;
  border-radius: 50%;
}
.news-content {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.title-right {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.desc {
  list-style-type: circle !important;
}

.teacher-box {
  width: 1200px;
  height: 407px;
  background-image: url("../assets/img/teacher-swper.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .teacher-photo {
    position: absolute;
    left: 82px;
    // width: 308px;
    height: 477px;
    top: -85px;
  }
  .key-index {
    position: absolute;
    right: 80px;
    bottom: 50px;
    color: #000000;
  }
  .teacher-info {
    position: absolute;
    left: 460px;
    top: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    color: #000000;

    > :nth-child(1) {
      font-size: 36px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 40px;
    }

    > :nth-child(2) {
      margin-top: 20px;
    }
  }
}
/deep/ .el-carousel__container {
  overflow: visible;
  height: 410px;
}

/deep/ .el-carousel__container .el-carousel__item .swiper-img {
  // width: 100%;
  // // height: 560px;
  // height: 100%;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

/deep/ .el-carousel__item.is_animating {
  transition: 1s all;
}

/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/deep/ .el-carousel__item .is-active {
  display: flex;
}

/deep/ .el-pager li.active {
  color: #f20d0d;
}

/deep/ .el-pager li:hover {
  color: #f20d0d;
}

/deep/.el-carousel__arrow {
  width: 50px;
  height: 50px;
}

/deep/ .el-input__inner:focus {
  border-color: #f20d0d !important;
}

/deep/ .el-pagination button:hover {
  color: #f20d0d;
}

.scroll-bar {
  &::-webkit-scrollbar-track {
    // border-radius: 10px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: rgba(0, 0, 0, 0.2);
    background-color: #888;
  }
}
</style>
