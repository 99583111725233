<template>
  <div class="md-item-class-component" @click="turnVideo">
    <img v-if="classInfo.cover" :src="`/video-dev${classInfo.cover}`" alt="">
    <p class="title ellipsis2">{{ classInfo.title }}</p>
    <div></div>
    <div class="footer"> <img v-if="classInfo.lecturerAvatar" :src="`/video-dev${classInfo.lecturerAvatar}`" alt="">
      <img v-else src="../../../assets/img/teacheravater.png" alt="">
      <span>{{
        classInfo.lecturer }}</span> <span>点击查看</span>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    classInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem('isLogin')
    },
    userInfo() {
      return JSON.parse(window.localStorage.getItem('userInfo'))
    }
  },
  methods: {
  
    turnVideo() {
      if (this.isLogin) {
       
        if (this.classInfo.videoId) {
     
          this.$router.push({
            path: '/courseDetail',
            query: {
              rid: this.classInfo.videoId,
              sysVideoId: this.classInfo.sysVideoId,
            }
          })
        } else {
          console.log(22222);
          this.$router.push({
            path: '/courseDetail',
            query: {
              rid: this.classInfo.rid,
              sysVideoId: this.classInfo.sysVideoId,
            }
          })
        }

      } else {
        this.$confirm('是否去登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            path: '/login'
          })
        }).catch(() => { });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.md-item-class-component {
  position: relative;
  width: 285px;
  height: 308px;
  float: left;
  margin: 0 20px 20px 0;
  box-shadow: 0 4px 8px 0 rgba(95, 101, 105, .05);
  border-radius: 8px;
  background-color: #fff;
  transition: transform .2s, box-shadow .2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 20px 0 rgba(95, 101, 105, .1);
  }

  img {
    width: 100%;
    height: 190px;
    background: no-repeat center/cover;
    margin-bottom: 8px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .title {
    width: 100%;
    color: #000000;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: SourceHanSansCN, SourceHanSansCN;
    margin-top: 8px;
    padding: 0 8px;

    &:hover {
      color: @md-primary-color;
    }
  }

  .ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .one,
  .two {
    font-size: 12px;
    color: #9199a1;
    line-height: 18px;
    padding: 0 8px;
    margin-bottom: 8px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    position: absolute;
    bottom: 12px;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin: 0;

    }

    >:nth-child(2) {
      color: #000000;
      margin-left: 8px;
      font-size: 12px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }

    >:nth-child(3) {
      width: 96px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      background-color: @md-primary-color;
      color: #fff;
      border-radius: 16px;
      text-align: center;
      position: absolute;
      right: 12px;
    }
  }
}
</style>