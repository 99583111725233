<template>
  <div class="md-item-class-component" @click="turnVideo(classInfo)">
    <img v-if="classInfo.cover" :src="`/video-dev${classInfo.cover}`" alt="" />
    <div class="right-box">
      <p class="status">{{ rendertime() }}</p>
      <p class="title ellipsis2">{{ classInfo.name }}</p>
      <p class="one">
        <img
          style="width: 20px; height: 20px"
          src="@/assets/img/fire.png"
          alt=""
        />
        <span> 最近在学 {{ classInfo.applyNum || 12 }}人</span>
        <span v-if="title == '看回放'" class="goLive" @click="golive">
          {{ title }}</span
        >
        <span
          v-else-if="title == '未开始'"
          style="background-color: #afbfcc"
          class="goLive"
          @click="golive"
        >
          {{ title }}</span
        >
        <span v-else class="goLive" @click="golive"> {{ title }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: "看回放",
    };
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem("isLogin");
    },
    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
  },
  methods: {
    golive() {},
    rendertime() {
      let nowtimeS = new Date().getTime();
      let startTime = new Date(this.classInfo.liveStartTime).getTime();
      let endtime = new Date(this.classInfo.liveEndTime).getTime();

      if (nowtimeS < startTime) {
        this.title = "未开始";
        return this.classInfo.liveStartTime + "开课";
      } else if (nowtimeS > startTime && nowtimeS < endtime) {
        this.title = "直播中";
        return "直播中";
      } else {
        this.title = "看回放";
        return "已开播";
      }
    },
    turnVideo(item) {
      if (this.isLogin) {
        if (item.video !== "") {
          let routeData = this.$router.resolve({
            path: "/livevideo",
            query: {
              vUrl: "https://www.coollearn.cn/video-dev/" + item.video,
            },
          });
          window.open(routeData.href, "_blank");
        } else {
          this.$message.error("暂无视频，敬请期待");
        }
      } else {
        console.log(item);
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.md-item-class-component {
  position: relative;
  width: 590px;
  height: 222px;
  float: left;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(95, 101, 105, 0.05);
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 20px 0 rgba(95, 101, 105, 0.1);
  }

  img {
    width: 285px;
    height: 190px;
    background: no-repeat center/cover;
    margin-bottom: 5px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .title {
    width: 100%;
    color: #000000;
    line-height: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 0 8px;

    &:hover {
      color: @md-primary-color;
    }
  }

  .status {
    color: #999999;
    font-size: 12px;
    padding-left: 12px;
    margin: 0;
  }

  .ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .one {
    width: 289px;
    font-size: 12px;
    color: #9199a1;
    padding: 0 8px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    bottom: 12px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .two {
    font-size: 12px;
    color: #9199a1;
    line-height: 18px;
    padding: 0 8px;
    margin-bottom: 8px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .goLive {
    line-height: 24px;
    background-color: @md-primary-color;
    border-radius: 16px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    display: inline-block;
    position: absolute;
    right: 10px;
    width: 90px;
    height: 32px;
    line-height: 32px;
    background: #ff882e;
    border-radius: 16px;
  }
  .right-box {
    width: 289px;
    height: 190px;
  }
}
</style>
